<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="操作类型">
          <el-select
            v-model="searchData.stockType"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in stockType"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作时间">
          <el-date-picker
            style="width: 250px"
            size="small"
            v-model="searchData.timeSlot"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" " label-width="50px">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="getList()"
            >搜索</el-button
          >
          <el-button
            type="info"
            size="small"
            icon="el-icon-refresh"
            @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
  </div>
</template>

<script>
import { stockType } from "@/enum/dict/index.js";
import { getListAPI } from "./api";
const columns = [
  {
    label: "商品分类",
    prop: "category",
    minWidth: "110",
    customRender(h, row) {
      return <p>{row["category"]?.["categoryName"]}</p>;
    },
  },
  {
    label: "商品名称",
    prop: "goods",
    minWidth: "160",
    customRender(h, row) {
      return (
        <div>
          <p>{row["goods"]?.["goodsTitle"]}</p>
          <p>{row["goods"]?.["subTitle"]}</p>
        </div>
      );
    },
  },
  {
    label: "规格名称",
    prop: "goodsSku",
    minWidth: "160",
    customRender(h, row) {
      return <p>{row["goodsSku"]?.["skuTitle"]}</p>;
    },
  },
  {
    label: "操作类型",
    prop: "stockType",
    minWidth: "100",
    customRender(h, row) {
      return (
        <p>
          <el-tag type="success" size="small" v-show={row["stockType"] == 1}>
            入库
          </el-tag>
          <el-tag type="primary" v-show={row["stockType"] == 2}>
            出库
          </el-tag>
        </p>
      );
    },
  },

  {
    label: "变动数量",
    prop: "stockNum",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["stockNum"]}</p>;
    },
  },
  {
    label: "明细说明",
    prop: "subject",
    minWidth: "150",
    customRender(h, row) {
      return <p>{row["subject"]}</p>;
    },
  },
  {
    label: "详细备注",
    prop: "remark",
    minWidth: "150",
    customRender(h, row) {
      return <p>{row["remark"]}</p>;
    },
  },

  {
    label: "操作时间",
    prop: "createTime",
    width: "180",
    fixed: "right",
    customRender(h, row) {
      return <p>{row["createTime"]}</p>;
    },
  },
];
export default {
  name: "List",

  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        stockType: "",
        timeSlot: [],
        leftCreateDate: "",
        rightCreateDate: "",
      },
      stockType,
    };
  },
  provide() {
    return {
      context: this,
    };
  },
  activated() {
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList();
    },
    pageSize() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },

  methods: {
    async getList() {
      const { stockType, timeSlot } = this.searchData;
      let params = {
        stockType,
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0] || "";
        params.rightCreateDate = timeSlot[1] || "";
      }
      const res = await getListAPI(params);
      this.list = res.list || [];
      this.total = res.total || 0;
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData);
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
        .el-input {
          width: 250px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
            margin: 0 5px;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
}
</style>
